import React from 'react';

export interface ErrorPageProps {
  error: Error;
  header?: string;
}
export const ErrorPage: React.FC<ErrorPageProps> = ({ header, error }) => {
  return (
    <section>
      {header && <h1>{header}</h1>}
      <details>{error.message}</details>
    </section>
  );
};

export default ErrorPage;
