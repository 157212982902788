export const getIngredient = (id: IngredientKey): Ingredient => {
  return IngredientMap.get(id) as Ingredient;
};
export enum IngredientKey {
  AQUAFABA,
  BAKING_SODA,
  BAKING_POWDER,
  BUTTER_UNSALTED,
  CARDAMOM,
  CINNAMON,
  COFFEE,
  COCO_POWDER,
  CHOCOLATE_CHIPS,
  COCONUT_OIL_VIRGIN,
  COCONUT_SUGAR,
  CREAM_OF_TARTAR,
  EGG,
  EGG_WHITE,
  FLOUR_AP,
  FLOUR_WW_PASTRY,
  MILK,
  MOLASSES_UNSULFURED,
  SALT,
  SALT_KOSHER,
  SALT_SEA,
  SUGAR,
  SUGAR_CONFECTIONERS,
  SUGAR_LIGHT_BROWN,
  SWEET_POTATO,
  VANILLA_EXTRACT,
  VEGETABLE_OIL,
  VINEGAR_APPLE_CIDER,
  WATER,
  YEAST,
}

export const createIngredientMap = (
  ...ingArrays: Array<Array<IngredientKey | string>>
) =>
  new Map<IngredientKey, Ingredient>(
    ingArrays.map((kvPair) => [
      kvPair[0] as IngredientKey,
      { id: kvPair[0], name: kvPair[1] } as Ingredient,
    ])
  );

export const IngredientMap = createIngredientMap(
  [IngredientKey.AQUAFABA, 'Aquafaba'],
  [IngredientKey.BAKING_SODA, 'Baking Soda'],
  [IngredientKey.BAKING_POWDER, 'Baking Powder'],
  [IngredientKey.BUTTER_UNSALTED, 'Unsalted Butter'],
  [IngredientKey.CHOCOLATE_CHIPS, 'Chocolate Chips'],
  [IngredientKey.COFFEE, 'Coffee'],
  [IngredientKey.CINNAMON, 'Cinnamon'],
  [IngredientKey.CARDAMOM, 'Cardamom'],
  [IngredientKey.COCO_POWDER, 'Cocoa Powder'],
  [IngredientKey.COCONUT_OIL_VIRGIN, 'Virgin Coconut Oil'],
  [IngredientKey.COCONUT_SUGAR, 'Coconut Sugar'],
  [IngredientKey.CREAM_OF_TARTAR, 'Cream of Tartar'],
  [IngredientKey.EGG, 'Egg'],
  [IngredientKey.EGG_WHITE, 'Egg White'],
  [IngredientKey.FLOUR_AP, 'All Purpose Flour'],
  [IngredientKey.FLOUR_WW_PASTRY, 'Whole Wheat Pastry Flour'],
  [IngredientKey.MILK, 'Milk'],
  [IngredientKey.MOLASSES_UNSULFURED, 'Unsulfured Molasses'],
  [IngredientKey.SALT, 'Salt'],
  [IngredientKey.SALT_KOSHER, 'Kosher Salt'],
  [IngredientKey.SALT_SEA, 'Sea Salt'],
  [IngredientKey.SUGAR, 'Sugar'],
  [IngredientKey.SUGAR_CONFECTIONERS, 'Confectioners Sugar'],
  [IngredientKey.SUGAR_LIGHT_BROWN, 'Light Brown Sugar'],
  [IngredientKey.SWEET_POTATO, 'Sweet Potato'],
  [IngredientKey.VANILLA_EXTRACT, 'Vanilla Extract'],
  [IngredientKey.VEGETABLE_OIL, 'Vegetable Oil'],
  [IngredientKey.VINEGAR_APPLE_CIDER, 'Apple Cider Vinegar'],
  [IngredientKey.WATER, 'Water'],
  [IngredientKey.YEAST, 'Yeast']
);
