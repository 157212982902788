import React from 'react';

import { ColorOption, getBackgroundStyles } from '../../styles/utils';
import classes from './chip.module.css';

export interface ChipProps {
  close?: boolean;
  color?: ColorOption;
}
export const Chip: React.FC<ChipProps> = ({
  children,
  close,
  color = ColorOption.GREY,
}) => {
  const chipStyles = getBackgroundStyles({ color });
  return (
    <div className={classes.chip} style={chipStyles}>
      {children}
      {close && <i className="close material-icons">close</i>}
    </div>
  );
};

export default Chip;
