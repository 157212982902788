import React from 'react';

import { useRecipeFilter } from '../../state';
import SearchInput from '../../components/common/SearchInput';
import classes from './filter-form.module.css';

export interface FilterFormProps {
  recipes: Recipe[];
  placeholder?: string;
  onSearch?: (state: FilterFormResults) => void;
}

export interface FilterFormResults {
  results: Recipe[];
}

export const FilterForm: React.FC<FilterFormProps> = ({
  placeholder = 'Search for recipes',
}) => {
  const { filter, setFilter } = useRecipeFilter();

  const onSearchChange = (search: string) => {
    setFilter({ search, tagIds: filter.tagIds });
  };

  const onReset = () =>
    setFilter({
      search: '',
      tagIds: [],
    });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.debug('Search Submit');
  };

  return (
    <form className={classes.form} onSubmit={onSubmit} onReset={onReset}>
      <SearchInput
        label={placeholder}
        placeholder="Type to filter..."
        onChange={onSearchChange}
        value={filter.search}
      />
    </form>
  );
};

export default FilterForm;
