import React from 'react';
import { useHistory } from 'react-router-dom';

import ErrorBoundary from '../ErrorBoundary';
import { ErrorComponentProps, ErrorPage } from '..';
import RecipeCardList from '../common/RecipeCardList';

import { useFavorites } from '../../state';
import { FilterForm } from '../common/FilterForm';

export const FavoritesDashboard: React.FC = () => {
  const { favorites } = useFavorites();
  const history = useHistory();

  const onSelect = (recipe: Recipe) => {
    history.push(`/recipe/${recipe.id}`);
  };

  const ErrorComponent = (props: ErrorComponentProps) => (
    <ErrorPage header="Error retrieving recipes:" {...props} />
  );

  return (
    <ErrorBoundary ErrorComponent={ErrorComponent}>
      <FilterForm recipes={favorites} placeholder="Search Favorites..." />
      <RecipeCardList recipes={favorites} onSelect={onSelect} />
    </ErrorBoundary>
  );
};

export const SuspendedFavoritesDashboard: React.FC = (props) => (
  <React.Suspense fallback={<h2>Loading Favorites...</h2>}>
    <FavoritesDashboard {...props} />
  </React.Suspense>
);
export default SuspendedFavoritesDashboard;
