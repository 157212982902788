import React, { HtmlHTMLAttributes } from 'react';

import './tooltip.module.css';

type ColorLevel =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'default';

export interface TooltipProps extends HtmlHTMLAttributes<any> {
  text: string;
  location?: 'top' | 'bottom' | 'right' | 'left';
  style?: object;
  dimHover?: boolean;
  level?: ColorLevel;
}
export const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  dimHover,
  location = 'right',
  level = 'default',
  ...props
}) => {
  return (
    <span data-tooltip={text} data-tooltip-location={location} {...props}>
      {children}
    </span>
  );
};

export default Tooltip;
