import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import RecipeDashboard from './components/browse/RecipeDashboard';
import RecipeDetails from './components/recipe/RecipeDetails';
import FavoritesDashboard from './components/favotrites/FavoritesDashboard';

export const Router: React.FC = () => {
  return (
    <Switch>
      <Route path="/browse" component={RecipeDashboard} />
      <Route path="/favorites" component={FavoritesDashboard} />
      <Route path="/recipe/:id" component={RecipeDetails} />
      <Redirect to="/browse" />
    </Switch>
  );
};

export default Router;
