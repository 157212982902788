import React from 'react';

import classes from './details-panel.module.css';
import { isMobile } from '../../utils';

export const DetailsPanel: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(!isMobile());

  const onDetailClick = (e: React.MouseEvent) => {
    e.preventDefault();
    isMobile() && setIsOpen(!isOpen);
  };

  return (
    <details
      className={classes.detailsPanel}
      open={isOpen}
      onClick={onDetailClick}
    >
      {children}
    </details>
  );
};

export default DetailsPanel;
