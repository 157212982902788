import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import classes from './header.module.css';

interface HeaderProps extends RouteComponentProps {
  brand: string;
  subtext?: string;
  actions?: React.ComponentType;
}
export const Header = withRouter(({ brand, actions }: HeaderProps) => (
  <header className={classes.header}>
    <div className={classes.menu}>
      <NavLink className={classes.brandLink} to="/">
        {brand}
      </NavLink>

      <ul className={classes.actionArea}>
        <ActionItem value="Browse" to="/browse" />
        <ActionItem value="Favorites" to="/favorites" />
        {actions}
      </ul>
    </div>
  </header>
));

export interface ActionItemProps {
  value: React.ReactNode;
  to?: string;
}
export const ActionItem: React.FC<ActionItemProps> = ({ value, to }) => (
  <li className={classes.actionItem}>
    {to ? (
      <NavLink to={to} activeClassName="active">
        {value}
      </NavLink>
    ) : (
      value
    )}
  </li>
);

export default Header;
