import React from 'react';

import classes from './recipe-details.module.css';
import Tooltip from '../common/Tooltip';
import { Icon, Ligature } from '../icons/Icon';

import { formatTime, getFraction } from '../../utils';
import { replaceDirectionIngredient } from '../../mocks/helpers';

export const Method: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const sorted = [...recipe.steps].sort((a, b) =>
    a.order >= b.order ? 1 : -1
  );
  return (
    <article className={classes.recipeMethod}>
      <h1>
        Method <InfoTooltip />
      </h1>
      <table>
        <tbody>
          {sorted.map((step, i) => (
            <StepRow key={`${recipe.id}-step-${i}`} step={step} />
          ))}
        </tbody>
      </table>
    </article>
  );
};

export const StepRow: React.FC<{ step: Step }> = ({ step }) => {
  return (
    <tr className={classes.recipeStep}>
      <td>{step.order + 1}.</td>
      <td>
        <aside>
          <span role="img" aria-label="Timing">
            🕑 &nbsp;{formatTime(step.time)} {step.active && 'active'}
          </span>
        </aside>
        <p>{<TooltipDirections step={step} />}</p>
        <aside>
          {step.notes && (
            <span
              role="img"
              aria-label="Notes"
              onClick={(e) => e.preventDefault()}
            >
              💬 &nbsp;{step.notes}
            </span>
          )}
        </aside>
      </td>
      <td className={classes.recipeStepDetails}></td>
    </tr>
  );
};

export const TooltipDirections: React.FC<{ step: Step }> = ({ step }) => {
  const slicedDirections: any = step.ingredients.reduce(
    (hydrated, si) =>
      replaceDirectionIngredient(
        si.ingredient,
        hydrated.replace(
          new RegExp(si.ingredient.name, 'ig'),
          `;;${si.ingredient.name};;`
        ),
        `;;${si.ingredient.name};;`
      ),
    step.directions
  );

  return slicedDirections.split(';;').map((w: string) => {
    const si = step.ingredients.find(
      (i) => i.ingredient.name.toLowerCase() === w.toLowerCase()
    ) as StepIngredient;

    if (!si) return w;

    const fraction = getFraction(si.amount, 8, { asString: true }) as string;
    const amountText = `${fraction}${
      si.measurement.short || si.measurement.name
    }`;

    return si && w === si.ingredient.name ? (
      <Tooltip
        key={`${si.ingredient.id}-tooltip`}
        text={amountText}
        className={classes.ingredientTooltip}
        location="bottom"
      >
        {si.ingredient.name}
      </Tooltip>
    ) : (
      w
    );
  });
};

export const InfoTooltip: React.FC = () => (
  <Tooltip
    dimHover
    text="Hover over underlined ingredients to get measures"
    location="right"
    style={{ width: 'auto', verticalAlign: 'middle' }}
  >
    <Icon ligature={Ligature.INFO} modifier="outline" />
  </Tooltip>
);

export default Method;
