import React from 'react';

import Header from './Header';
import './layout.css';

export interface LayoutProps {}
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div id="layout" className="ease-transition">
      <Header brand="Recipe Tracker" />
      <div id="main">
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
