import React from 'react';

import { Grid, GridItem } from '../../components/common/Grid';
import RecipeSummaryCard from './RecipeSummaryCard';

export interface RecipeCardListProps {
  recipes: Recipe[];
  onSelect: (recipe: Recipe) => void;
}
export const RecipeCardList: React.FC<RecipeCardListProps> = ({
  recipes,
  onSelect,
}) => {
  const selectRecipe = (recipe: Recipe) => () => {
    onSelect(recipe);
  };

  return (
    <Grid>
      {recipes.map((recipe) => (
        <GridItem key={recipe.id}>
          <RecipeSummaryCard onClick={selectRecipe(recipe)} recipe={recipe} />
        </GridItem>
      ))}
    </Grid>
  );
};

export default RecipeCardList;
