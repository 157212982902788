import React from 'react';
import styles from './card.module.css';

export interface CardProps {
  onClick?: () => void;
  className?: string;
  style?: any;
}
export const Card: React.FC<CardProps> = ({
  children,
  onClick,
  className,
  style,
  ...props
}) => {
  const classNames = `${styles.card} ${onClick ? styles.clickable : ''} ${
    className || ''
  }`;

  const inlineStyles = {
    ...(style || {}),
  };

  return (
    <div
      className={classNames}
      onClick={onClick}
      style={inlineStyles}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
