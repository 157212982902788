import React from 'react';

export interface IconProps extends React.ComponentProps<any> {
  ligature: Ligature;
  modifier?: string;
  color?: string;
}
export const Icon: React.FC<IconProps> = ({
  ligature,
  modifier,
  color = 'black',
  style = {},
  ...props
}) => (
  <i style={{ color, ...style }} className="material-icons" {...props}>
    {`${ligature}${modifier ? `_${modifier}` : ''}`}
  </i>
);

export enum Ligature {
  STAR = 'star',
  INFO = 'info',
  TIMER = 'timer',
}
