import React from 'react';
import { HashRouter } from 'react-router-dom';

import StoreProvider from './state/StoreProvider';
import Layout from './components/layout/Layout';
import Router from './Router';
import ErrorBoundary, { ErrorComponentProps } from './components/ErrorBoundary';

function ErrorComponent({ error }: ErrorComponentProps) {
  return (
    <section>
      <h1>Fatal Error:</h1>
      <details>{error.message}</details>
    </section>
  );
}

class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <Layout>
          <StoreProvider>
            <section>
              <ErrorBoundary ErrorComponent={ErrorComponent}>
                <Router />
              </ErrorBoundary>
            </section>
          </StoreProvider>
        </Layout>
      </HashRouter>
    );
  }
}
export default App;
