import React from 'react';

import { getFraction } from '../../utils';
import { DetailsPanel } from '../common';
import classes from './recipe-details.module.css';

export const IngredientList: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const allIngredients: StepIngredient[] = recipe.steps.reduce(
    (agg: any, step: Step) => [...agg, ...step.ingredients],
    []
  );
  const ingredientMap = allIngredients.reduce(
    (agg: any, curr: StepIngredient) => {
      const existing: any = agg[curr.ingredient.id] || { amount: '' };
      const measure = curr.measurement.short || curr.measurement.name;
      return {
        ...agg,
        [curr.ingredient.id]: {
          ...existing,
          ...curr,
          amount: `${existing.amount} + ${getFraction(
            curr.amount,
            8
          )} ${measure}`,
        } as StepIngredient,
      };
    },
    {}
  );

  const ingredients: StepIngredient[] = Object.values(ingredientMap);

  return (
    <section className={classes.recipeIngredients}>
      <DetailsPanel>
        <summary>
          <h1>Ingredients</h1>
        </summary>
        <table>
          <tbody>
            {ingredients.length === 0 && <h2>No Ingredients</h2>}
            {ingredients.map((si: StepIngredient) => (
              <tr key={si.ingredient.id}>
                <td data-title="Ingredient">{si.ingredient.name}</td>
                <td data-title="Amount">
                  {si.amount.toString().replace(/^( )*\+/, '')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DetailsPanel>
    </section>
  );
};

export default IngredientList;
