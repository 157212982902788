import React from 'react';

import { Card } from './Card';
import { Chip } from './Chip';
import classes from './recipe-card.module.css';
import { ColorOption } from '../../styles/utils';
import { defaultImage } from '../../mocks/recipes';

export interface RecipeSummaryCardProps {
  recipe: Recipe;
  onClick: () => void;
}

export const RecipeSummaryCard: React.FC<RecipeSummaryCardProps> = ({
  recipe,
  onClick,
}) => {
  const image = Array.isArray(recipe.images) ? recipe.images[0] : null;
  const style = { backgroundImage: `url("${image || defaultImage}")` };

  const tags = recipe.tags
    ? recipe.tags.map((tag) => (
        <Chip key={tag.id} color={ColorOption.SECONDARY}>
          {tag.title}
        </Chip>
      ))
    : null;

  return (
    <Card className={classes.card} onClick={onClick}>
      <div className={classes.media} style={style}></div>
      <div className={classes.content}>
        <h3 className={classes.heading}>{recipe.title}</h3>
        <div className={classes.tags}>{tags}</div>
      </div>
    </Card>
  );
};

export default RecipeSummaryCard;
