import { atom } from 'recoil';

/**
 * See docs for atoms: https://recoiljs.org/docs/introduction/getting-started#atom
 */

export enum Keys {
  RECIPES = 'recipesState',
  TAGS = 'tagsState',
  INGREDIENTS = 'ingredientsState',
  FILTER = 'filterState',
  FAVORITES = 'favoritesState',
}

export const tagsAtom = atom({
  key: Keys.TAGS,
  default: [
    { id: 'sweets', title: 'Sweets' },
    { id: 'vegan', title: 'Vegan' },
    { id: 'vegetarian', title: 'Vegetarian' },
    { id: 'chocolate', title: 'Chocolate' },
    { id: 'icing', title: 'Icing' },
    { id: 'cookies', title: 'Cookies' },
    { id: 'bakery', title: 'Bakery' },
    { id: 'chinese', title: 'Chinese' },
  ] as Tag[],
});

export const ingredientsAtom = atom({
  key: Keys.INGREDIENTS,
  default: [],
});

export const recipesAtom = atom<Recipe[]>({
  key: Keys.RECIPES,
  default: [],
});

export const FAVORITES_STORAGE_KEY = 'favorite_recipes';
export type FavoritesMap = { [id: string]: boolean };
export const readFavorites = () => {
  try {
    const value = localStorage.getItem(FAVORITES_STORAGE_KEY) as string;
    return JSON.parse(value) || {};
  } catch (e) {
    return {};
  }
};

export const storeFavorites = (val: FavoritesMap) => {
  localStorage.setItem(FAVORITES_STORAGE_KEY, JSON.stringify(val));
  return val;
};
export const favoritesMapAtom = atom<{ [id: string]: boolean }>({
  key: Keys.FAVORITES,
  default: readFavorites(),
});

export interface FilterState {
  search: string;
  tagIds: string[];
}
export const filterAtom = atom<FilterState>({
  key: Keys.FILTER,
  default: {
    search: '',
    tagIds: [],
  },
});
