import React from 'react';
import { useHistory } from 'react-router-dom';

import ErrorBoundary from '../../components/ErrorBoundary';
import { ErrorComponentProps, ErrorPage } from '../../components';
import RecipeCardList from '../common/RecipeCardList';

import { useRecipeFilter } from '../../state';
import { FilterForm } from '../common/FilterForm';

export const RecipeDashboard: React.FC = () => {
  const { recipes } = useRecipeFilter();
  const history = useHistory();

  const onSelect = (recipe: Recipe) => {
    history.push(`/recipe/${recipe.id}`);
  };

  const ErrorComponent = (props: ErrorComponentProps) => (
    <ErrorPage header="Error retrieving recipes:" {...props} />
  );

  return (
    <ErrorBoundary ErrorComponent={ErrorComponent}>
      <FilterForm recipes={recipes} />
      <RecipeCardList recipes={recipes} onSelect={onSelect} />
    </ErrorBoundary>
  );
};

export const SuspendedRecipeDashboard: React.FC = (props) => (
  <React.Suspense fallback={<h2>Loading Recipes...</h2>}>
    <RecipeDashboard {...props} />
  </React.Suspense>
);
export default SuspendedRecipeDashboard;
