import { randomId } from '../utils';
import { IngredientKey, IngredientMap } from './ingredients';

export const generateId = () => randomId();

export const flagIngredient = (ingKey: IngredientKey) => `[::${ingKey}::]`;
export const hydrateIngredientId = (ingKey: IngredientKey, source: string) =>
  source.replace(
    new RegExp(`\\[::${ingKey}::\\]`),
    `[::${(IngredientMap.get(ingKey) as any).id}::]`
  );

export const replaceDirectionIngredient = (
  ing: Ingredient,
  source: string,
  replace: any
) => source.replace(new RegExp(`\\[::${ing.id}::\\]`, 'ig'), replace);
