import { selector } from 'recoil';

import { getRecipes } from '../mocks/recipes';

export const TAGS: Tag[] = [
  { id: 'vegetarian', title: 'Vegetarian' },
  { id: 'vegan', title: 'Vegan' },
  { id: 'sweets', title: 'Sweets' },
  { id: 'bread', title: 'Bread' },
];

export const KEYS = {
  recipeSelector: 'Recipes',
  favoritesSelector: 'Favorites',
};

export const recipeQuery = selector<Recipe[]>({
  key: KEYS.recipeSelector,
  get: async () => {
    const mockResponse = await Promise.resolve(getRecipes());
    return mockResponse;
  },
});
