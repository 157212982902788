import React from 'react';

export interface ErrorComponentProps {
  error: Error;
}

export interface ErrorBoundaryProps {
  ErrorComponent?: React.ComponentType<ErrorComponentProps>;
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    error: { message: '', stack: '' } as Error,
    info: { componentStack: '' },
  };

  static getDerivedStateFromError = (error: Error) => {
    return { hasError: true };
  };

  componentDidCatch = (error: Error, info: any) => {
    this.setState({ error, info });
  };

  renderDefaultErrorComponent = () => {
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {this.state.error && this.state.error.message}
        </details>
      </div>
    );
  };

  render() {
    const { ErrorComponent } = this.props;

    if (this.state.hasError) {
      return ErrorComponent ? (
        <ErrorComponent error={this.state.error} />
      ) : (
        this.renderDefaultErrorComponent()
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
