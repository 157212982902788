export enum ColorOption {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  GREY,
  DEFAULT,
}

type ColorGetterOptions = { color?: ColorOption; shade?: string };

export const getColorValue = ({
  color = ColorOption.DEFAULT,
  shade,
}: ColorGetterOptions) => {
  const shadeString = shade ? `-${shade}` : '';

  switch (color) {
    case ColorOption.PRIMARY:
      return `var(--color-primary${shadeString})`;
    case ColorOption.SECONDARY:
      return `var(--color-secondary${shadeString})`;
    case ColorOption.TERTIARY:
      return `var(--color-tertiary${shadeString})`;
    default:
      return `var(--color-${color.toString()}${shadeString})`;
  }
};
export const getBackgroundStyles = ({
  color = ColorOption.DEFAULT,
  shade,
}: ColorGetterOptions) => {
  const shadeString = shade ? `-${shade}` : '';

  switch (color) {
    case ColorOption.PRIMARY:
      return {
        backgroundColor: `var(--color-primary${shadeString})`,
        color: `var(--color-primary-text)`,
      };
    case ColorOption.SECONDARY:
      return {
        backgroundColor: `var(--color-secondary${shadeString})`,
        color: `var(--color-secondary-text)`,
      };
    case ColorOption.TERTIARY:
      return {
        backgroundColor: `var(--color-tertiary${shadeString})`,
        color: `var(--color-tertiary-text)`,
      };
    default:
      return {
        backgroundColor: `var(--color-background)`,
        color: `var(--color-text-default)`,
      };
  }
};
