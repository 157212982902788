import * as helpers from './helpers';

export const defaultImage = `https://cmkt-image-prd.freetls.fastly.net/0.1.0/ps/3288820/910/606/m2/fpnw/wm1/ppfaihtnwhrvk16cka5tvuagvjmjqfwojq7799xs3holnnth1rbw7morzeqafl2v-.jpg?1506025562&s=07a0291fc9a8a94028dceff81ab65540)`;
// const rawRecipes = [VeganCupcakes, CoffeeCardamomCookies, RoyalIcing];

export const getRecipes = async (): Promise<Recipe[]> => {
  const recipeModule = await import('./recipes/index');

  if (!Array.isArray(recipeModule.default)) {
    console.debug('Invalid recipes.');
    return [];
  }

  return recipeModule.default.map((r, id) => {
    return {
      ...r,
      id: r.id ? r.id : id.toString(),
      steps: mapSteps(r as Recipe),
    };
  });
};

const mapSteps = (r: Recipe) =>
  r.steps.map((rs: Step) => {
    let newDirections = rs.directions;
    rs.ingredients.forEach((rsi: StepIngredient) => {
      newDirections = helpers.hydrateIngredientId(
        rsi.ingredient.id,
        newDirections
      );
    });
    return {
      ...rs,
      directions: newDirections,
    };
  });
