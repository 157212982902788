import React from 'react';

import { useFavorites } from '../../state/hooks';

import classes from './hero.module.css';
import { Icon, Ligature } from '../icons/Icon';

export interface HeroProps {
  recipe: Recipe;
  imageUrl?: string;
}
export const Hero: React.FC<HeroProps> = ({ imageUrl, recipe, ...props }) => {
  const { favorites, addFavorite, removeFavorite } = useFavorites();

  const styles = {
    backgroundImage: imageUrl ? `url('${imageUrl}')` : undefined,
    icon: { width: `1em`, cursor: 'pointer' },
  };

  const onFavorite = () => addFavorite(recipe.id);
  const onRemoveFavorite = () => removeFavorite(recipe.id);

  const isFavorite = favorites.some((f) => f.id === recipe.id);
  const iconModifier = isFavorite ? '' : 'outline';
  const favoriteAction = isFavorite ? onRemoveFavorite : onFavorite;
  const iconColor = isFavorite ? 'goldenrod' : 'black';

  return (
    <section className={classes.widget}>
      <div className={classes.content}>
        <div>
          <h1>
            <Icon
              ligature={Ligature.STAR}
              modifier={iconModifier}
              color={iconColor}
              style={styles.icon}
              onClick={favoriteAction}
              alt="Remove this recipe from favorites"
            />{' '}
            {recipe.title}
          </h1>
          <h2>{recipe.description}</h2>
        </div>
        <div className={classes.previewImage} style={styles}></div>
      </div>
    </section>
  );
};

export default Hero;
