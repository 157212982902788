import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  favoritesMapAtom,
  storeFavorites,
  filterAtom,
  FilterState,
} from './atoms';
import { recipeQuery } from './selectors';
import { filterRecipes } from '../utils';

type UseFavorites = () => {
  favorites: Recipe[];
  addFavorite: (id: ID) => void;
  removeFavorite: (id: ID) => void;
};
export const useFavorites: UseFavorites = () => {
  const [favoritesMap, setFavoritesMap] = useRecoilState(favoritesMapAtom);
  const recipes = useRecoilValue(recipeQuery);

  return {
    favorites: recipes.filter((r) => Boolean(favoritesMap[r.id])),
    addFavorite: (id: ID) => {
      const newVal = {
        ...favoritesMap,
        [id]: true,
      };
      storeFavorites(newVal);
      setFavoritesMap(newVal);
    },
    removeFavorite: (id: ID) => {
      const tmp = { ...favoritesMap };
      tmp[id] = false;

      storeFavorites(tmp);
      setFavoritesMap(tmp);
    },
  };
};

export interface UseRecipeFilter {
  recipes: Recipe[];
  setFilter: (filter: FilterState) => void;
  filter: FilterState;
}
export const useRecipeFilter = (): UseRecipeFilter => {
  const allRecipes = useRecoilValue(recipeQuery);
  const [filter, setFilter] = useRecoilState(filterAtom);
  const [recipes, setFilteredRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    setFilteredRecipes(
      filterRecipes(allRecipes, {
        title: filter.search,
        tagIds: filter.tagIds,
      })
    );
  }, [filter.search, filter.tagIds, allRecipes]);

  return { recipes, setFilter, filter };
};
