import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Hero from '../common/Hero';
import IngredientList from './IngredientList';
import Method from './Method';
import { recipeQuery } from '../../state';
import classes from './recipe-details.module.css';
import { formatTime } from '../../utils';

export const RecipeDetails: React.FC = () => {
  const { id } = useParams();
  const history = useHistory();
  const recipes = useRecoilValue(recipeQuery) as Recipe[];

  if (!id) history.push('/browse');

  const recipe = recipes && (recipes.find((r) => r.id === id) as Recipe);
  if (!recipe) history.push('/browse');

  const image = Array.isArray(recipe.images) ? recipe.images[0] : undefined;
  const timing = recipe.steps.reduce(
    (agg, curr) => ({
      active: curr.active ? agg.active + curr.time : agg.active,
      total: agg.total + curr.time,
    }),
    { active: 0, total: 0 }
  );

  return (
    <article className={classes.recipeWrapper}>
      <div className={classes.recipeHeader}>
        <Hero imageUrl={image} recipe={recipe} />
        <ul>
          <li>
            Yield:
            <span>
              {recipe.yield.count} {recipe.yield.description}
            </span>
          </li>
          <li>
            Active Time: <span>{formatTime(timing.active)}</span>
          </li>
          <li>
            Total Time: <span>{formatTime(timing.total)}</span>
          </li>
        </ul>
      </div>
      <Method recipe={recipe} />
      <IngredientList recipe={recipe} />
    </article>
  );
};

export const SuspendedRecipeDetails: React.FC = () => (
  <React.Suspense fallback={<h2>Loading Recipe Details...</h2>}>
    <RecipeDetails />
  </React.Suspense>
);

export default SuspendedRecipeDetails;
