import React from 'react';

import styles from './grid.module.css';

export const Grid: React.FC = ({ children }) => {
  return <div className={styles.grid}>{children}</div>;
};

export interface GridItemProps {}
export const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => {
  const className = ``;

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};

export default {
  Grid,
  GridItem,
};
